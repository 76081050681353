








import Avatar from "@/components/Avatars/Avatar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IStudent } from "@/interfaces/IStudent";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: { Avatar },
})
export default class StudentCol extends Vue {
  public name = "StudentCol";

  @Prop()
  public student!: IStudent;

  protected formatStudentFullName(student: IStudent): string {
    return formatStudentName(student.firstName, student.lastName);
  }
}
