



































import { Component, Prop, Vue } from "vue-property-decorator";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import StudentCol from "@/views/AutomaticPaymentsOverview/StudentCol.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { IStudent } from "@/interfaces/IStudent";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: { StudentCol, FscInputDecimal, Avatar },
})
export default class PaymentStudents extends Vue {
  public name = "PaymentStudents";

  @Prop({ default: () => [], type: Array })
  public items!: any;

  protected onDelete(item: any, index: number): void {
    this.$emit("on-delete", item, index);
  }

  protected formatStudentFullName(student: IStudent): string {
    return formatStudentName(student.lastName, student.firstName);
  }
}
